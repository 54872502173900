import { render, staticRenderFns } from "./AuthReminder.vue?vue&type=template&id=4702ea2c&scoped=true"
import script from "./AuthReminder.vue?vue&type=script&lang=js"
export * from "./AuthReminder.vue?vue&type=script&lang=js"
import style0 from "./AuthReminder.vue?vue&type=style&index=0&id=4702ea2c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4702ea2c",
  null
  
)

export default component.exports