<template>
  <div class="location-choice">
    <p class="location-choice__text">{{ texts[0] }}</p>
    <button class="location-choice__button btn btn-green" @click="btnClicked('green')">{{ texts[1] }}</button>
    <button class="location-choice__button btn btn-gray" @click="btnClicked('grey')">{{ texts[2] }}</button>
  </div>
</template>

<script>
export default {
  name: 'LocationConfirm',
  props: {
    mode: String
  },
  methods: {
    btnClicked(color) {
      if ((color === 'green' && this.mode === 'confirm') || (color === 'grey' && this.mode === 'manual')) {
        this.$emit('confirm');
      } else {
        this.$emit('adjust');
      }
    }
  },
  computed: {
    texts() {
      switch (this.mode) {
        case 'confirm':
          return ['Это ваш регион?', 'Да', 'Изменить'];
        case 'manual':
          return ['Мы не смогли определить ваш регион', 'Изменить', 'Оставить как есть'];
        default:
          return ['', '', ''];
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .location-choice {
    position: absolute;
    top: 17px;
    left: 5px;
    width: 205px;
    padding: 10px 15px 15px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      top: 8px;
      left: -5px;
      width: 14px;
      height: 14px;
      transform: rotate(45deg);
      background-color: #fff;
    }

    &__text, &__button:not(:last-child) {
      margin-bottom: 10px;
    }

    &__button {
      width: 100%;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }

    @media (max-width: 1023px) {
      width: 100%;
      top: auto;
      bottom: 0;
      right: 0;
      left: 0;

      &::before {
        display: none;
      }

      &__text,
      &__button {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
      }
    }
  }
</style>
