const partnerFilter = (partners, filter, activeDirections) => {
  const { filters, certificate } = filter

  let results = []
  results.push(...partners.filter(partner => partner.directions.some(direction => activeDirections.includes(direction.toUpperCase()))))

  if (filters.typeNames.length && !filters.productIds.length) {
    results = results.filter(p => filters.typeNames.includes(p.typeName))
  } else if (!filters.typeNames.length && filters.productIds.length) {
    results = results.filter(p => p.product_ids.find(id => filters.productIds.includes(id)) || filters.productIds.find(id => p.attributes_ids.includes(id)))
  } else if (filters.typeNames.length && filters.productIds.length) {
    results = results.filter(p => filters.typeNames.includes(p.typeName)).filter(p1 => p1.product_ids.find(id => filters.productIds.includes(id)) || filters.productIds.find(id => p1.attributes_ids.includes(id)))
  }

  if (certificate !== null) {
    results = [...results.filter(el => el.is_sertified === certificate)]
  }
  return results
}

export default partnerFilter
