export default {
  checkPrevSession() {
    const name = 'session';
    const matches = document.cookie.match(new RegExp(
      `(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`
    ));
    return !!matches
  },
  checkTodaySession() {
    const sessionCookie = document.cookie.split('; ').find(c => c.split('=')[0] === 'session');
    if (!sessionCookie) {
      return null;
    }
    const sessionDate = sessionCookie.split('=')[1];
    const today = new Date().toDateString();
    return sessionDate === today;
  },
  setSessionCookie() {
    const date = new Date().toDateString();
    document.cookie = `session=${date};expires=Fri, 31 Dec 9999 23:59:59 GMT`;
  },
  setLocationCookie(coords) {
    document.cookie = `coords=${JSON.stringify(coords)};max-age=84600`;
  },
  getLocationCookie() {
    const locationCookie = document.cookie.split('; ').find(c => c.split('=')[0] === 'coords');
    if (locationCookie) {
      return locationCookie.split('=')[1];
    }
    return null;
  }
}
