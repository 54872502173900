const facilityFilter = (objects, filter, savedRange, activeDirections) => {
  let results = []
  results.push(...objects.filter(partner => partner.directions.some(direction => activeDirections.includes(direction.toUpperCase()))))

  if (filter.typeNames.length && !filter.productIds.length) {
    results = results.filter(p => filter.typeNames.includes(p.typeName))
  } else if (!filter.typeNames.length && filter.productIds.length) {
    results = results.filter(p => p.product_ids.find(id => filter.productIds.includes(id)))
  } else if (filter.typeNames.length && filter.productIds.length) {
    results = results.filter(p => filter.typeNames.includes(p.typeName)).filter(p1 => p1.product_ids.find(id => filter.productIds.includes(id)))
  }

  if (savedRange.length && (savedRange[0] <= savedRange[1])) {
    results = [...results.filter(el => el.year_of_completion >= savedRange[0] && el.year_of_completion <= savedRange[1])]
  }

  return results
}

export default facilityFilter
