<template>
  <div class="product-list">
    <div v-for="direction in directions" :key="direction.id" class="product-list__item-wrap">
      <a class="product-list__item" v-show="isDirectionVisible(direction.code)">
        <img class="product__logo" :style="`border-color: ${colors[direction.code.toLowerCase()]}`"
          src="@/assets/images/svg/logo-small-transparent.svg" alt="">
        <p class="product-list__title">{{ direction.name }}</p>
      </a>
    </div>
  </div>
</template>

<script>
import colors from '@/helpers/DirectionColors';

export default {
  name: 'ProductList',
  data() {
    return {
      colors
    }
  },
  methods: {
    isDirectionVisible(code) {
      const visibleItemIds = this.$store.getters['getVisibles'];
      const availableItemIds = this.$store.getters['getAvailables'].map(obj => obj.id);
      let visibleItems;
      if (this.activeLayer === 'objects' && this.objects && this.objects.length) {
        visibleItems = this.objects.filter(obj => visibleItemIds.includes(obj.id) && availableItemIds.includes(obj.id));
      } else if (this.activeLayer === 'partners' && this.partners && this.partners.length) {
        visibleItems = this.partners.filter(obj => visibleItemIds.includes(obj.id) && availableItemIds.includes(obj.id));
      }
      let isVisible = false;
      if (visibleItems) {
        isVisible = visibleItems.some(obj => obj.directions.includes(code.toLowerCase()));
      }
      return isVisible;
    }
  },
  computed: {
    activeLayer() {
      return this.$store.getters['getActiveLayer'];
    },
    directions() {
      return this.$store.getters['getDirections'];
    },
    objects() {
      return this.$store.getters['getObjects'];
    },
    partners() {
      return this.$store.getters['getPartners'];
    }
  }
}
</script>

<style scoped>
.product-list {
  position: absolute;
  left: 30px;
  bottom: 30px;
  max-width: calc(100% - 100px);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, max-content));
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background: #FFFFFF;
}

.product-list__item {
  display: flex;
  align-items: center;
}

.product-list__item-wrap:not(:last-child) .product-list__item {
  margin-right: 30px;
}

.product-list__title {
  font-size: 16px;
  line-height: 14px;
  color: #000000;
}

.product__logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 6px;
  margin-right: 7px;
  border: 4px solid transparent;
}

.product__logo--green {
  border: 4px solid #58BCAF;
}

.product__logo--pink {
  border: 4px solid #DC143C;
}

.product__logo--black {
  border: 4px solid #000000;
}

.product__logo--gray {
  border: 4px solid #4E4E4E;
}

@media (max-width: 1440px) {
  .product-list {
    justify-content: flex-start;
  }

  .product-list__item {
    margin-bottom: 5px;
  }

  .product-list__item:not(:last-child) {
    margin-right: 0;
  }
}

@media (max-width: 1023px) {
  .product-list {
    display: flex;
    width: 100vw;
    left: 10px;
    bottom: 10px;
    padding: 0;
    overflow-x: auto;
    max-width: none;
    background-color: transparent;
  }

  .product-list__item-wrap {
    flex-shrink: 0;
  }

  .product-list__item {
    padding: 8px;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 0;
  }

  .product__logo {
    width: 30px;
    height: 30px;
    padding: 4px;
    border-width: 3px;
  }

  .product-list__item-wrap:not(:last-child) .product-list__item {
    margin-right: 8px;
  }

  .product-list__item-wrap:last-child {
    padding-right: 8px;
  }
}

@media (max-height: 499px) {
  .product-list {
    bottom: 5px;
  }
}
</style>
