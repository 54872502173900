import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    /**
    * @param {string} [directionIDs] - encoded destination identifiers of partners
    * @param {string} [filterIDs] - encoded Filter IDs
    * @param {boolean} [attributes] - attributes of a partner
    * @param {boolean | undefined} [certificate] - availability of a partner certificate
    */
    path: '/partners/results/:directionIDs/:filterIDs/:attributes/:certificate',
    component: () => import('@/components/SearchResults.vue'),
    meta: {
      hasScroll: false
    }
  },
  {
    /**
    * @param {string} [directionIDs] - encoded destination identifiers of partners
    * @param {string | boolean} [filterIDsAttributes] - encoded Filter IDs or attributes of a partner
    * @param {boolean} [attributesCertificate] - attributes of a partner or availability of a partner certificate
    */
    path: '/partners/results/:directionIDs/:filterIDsAttributes/:attributesCertificate',
    component: () => import('@/components/SearchResults.vue'),
    meta: {
      hasScroll: false
    }
  },
  {
    /**
    * @param {string} [directionIDs] - encoded destination identifiers of partners
    * @param {boolean} [attributes] - attributes of a partner
    */
    path: '/partners/results/:directionIDs/:attributes',
    component: () => import('@/components/SearchResults.vue'),
    meta: {
      hasScroll: false
    }
  },
  {
    /**
    * @param {string} [directionIDs] - encoded destination identifiers of partners
    * @param {string} [filterIDs] - encoded Filter IDs
    * @param {string} [range] - object year range
    */
    path: '/objects/results/:directionIDs/:filterIDs/:range',
    component: () => import('@/components/SearchResults.vue'),
    meta: {
      hasScroll: false
    }
  },
  {
    /**
    * @param {string} [directionIDs] - encoded destination identifiers of partners
    * @param {string} [filterIDsRange] - encoded filter IDs or feature year range
    */
    path: '/objects/results/:directionIDs/:filterIDsRange',
    component: () => import('@/components/SearchResults.vue'),
    meta: {
      hasScroll: false
    }
  },
  {
    /**
    * @param {string} [directionIDs] - encoded destination identifiers of partners
    */
    path: '/objects/results/:directionIDs',
    component: () => import('@/components/SearchResults.vue'),
    meta: {
      hasScroll: false
    }
  },
  {
    path: '/objects/filters',
    component: () => import('@/components/FacilityFilters.vue')
  },
  {
    path: '/partners/filters',
    component: () => import('@/components/PartnerFilters.vue')
  },
  {
    path: '/objects/:id',
    component: () => import('@/components/FacilityCard.vue'),
    meta: {
      hasScroll: false
    }
  },
  {
    path: '/partners/:id',
    component: () => import('@/components/PartnerCard.vue'),
    meta: {
      hasScroll: false
    }
  },
  {
    path: '/partners',
    component: () => import('@/components/DefaultState.vue')
  },
  {
    path: '/objects',
    component: () => import('@/components/DefaultState.vue')
  }
];

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});
