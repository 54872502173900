import Vue from 'vue';
import VueAxios from 'vue-axios';
import axios from 'axios';
import {
  Switch,
  Dropdown,
  Select,
  Option,
  Tooltip,
  Slider,
  Dialog
} from 'element-ui';
import locale from 'element-ui/lib/locale'
import lang from 'element-ui/lib/locale/lang/ru-RU';
import MessageService from '@/services/MessageService';

import App from './App.vue';
import store from './store';
import router from './router';

import 'external-svg-loader';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/scss/main.scss';

axios.defaults.withCredentials = true;
locale.use(lang);
Vue.use(Switch);
Vue.use(Dropdown);
Vue.use(Select);
Vue.use(Option);
Vue.use(Tooltip);
Vue.use(Slider);
Vue.use(Dialog);
Vue.use(VueAxios, axios);
Vue.use(new MessageService('MessageService', '$services'));

Vue.config.productionTip = false;

new Vue({
    store,
    router,
    render: (h) => h(App)
}).$mount('#app');
