<template>
  <div class="search-wrapper">
    <form @submit.prevent="search" class="input">
      <div class="search-box">
        <div class="search-icon-wrapper">
          <img
            @click="search"
            alt="искать"
            class="search-icon cursor-pointer"
            :class="{ 'filtered' : queryString }"
            src="@/assets/images/svg/search.svg">
        </div>
        <input type="text" v-model="queryString" class="search-input" :placeholder="placeHolder">
      </div>
      <div v-if="queryString" class="icon cursor-pointer" @click="clear">
        <img src="@/assets/images/svg/close.svg" alt="очистить">
      </div>
    </form>
  </div>
</template>

<script>
export default {
    name: 'Search',
    props: {
        placeHolder: String,
        updatedQuery: String
    },
    data() {
        return {
            queryString: null
        }
    },
    watch: {
        queryString() {
            this.$emit('query', this.queryString)
        },
        updatedQuery(val) {
          if (val === '') {
            this.queryString = val;
          }
        }
    },
    methods: {
        clear() {
            this.queryString = null
            this.$emit('query', '')
        },
        search() {
          this.$emit('search');
        }
    }
}
</script>

<style scoped>
  .filtered {
    filter: invert(9%) sepia(100%) saturate(5881%) hue-rotate(327deg) brightness(102%) contrast(101%)
  }

  @media (max-width: 1023px) {
    .search-wrapper {
      margin-bottom: 10px;
    }
  }
</style>
